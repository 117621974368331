
export const IsJWT = (token) => {
    const parts = token.split('.');
    if (parts.length !== 3) {
        return false;
    }

    const isBase64Url = (str) => {
        const base64UrlPattern = /^[A-Za-z0-9-_]+$/;
        return base64UrlPattern.test(str);
    };

    const [header, payload, signature] = parts;

    return isBase64Url(header) && isBase64Url(payload) && isBase64Url(signature);
}