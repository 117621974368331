export const GET_LIST_DOCUMENTS_REQUEST = "GET_LIST_DOCUMENTS_REQUEST";
export const GET_LIST_DOCUMENTS_SUCCESS = "GET_LIST_DOCUMENTS_SUCCESS";
export const GET_LIST_DOCUMENTS_FAILURE = "GET_LIST_DOCUMENTS_FAILURE";

export const DOCUMENT_SELECTED = "DOCUMENT_SELECTED";

export const SIGN_DOCUMENT_REQUEST = "SIGN_DOCUMENT_REQUEST";
export const SIGN_DOCUMENT_SUCCESS = "SIGN_DOCUMENT_SUCCESS";
export const SIGN_DOCUMENT_FAILURE = "SIGN_DOCUMENT_FAILURE";

export const UPDATE_LIST_DOCUMENT = 'UPDATE_LIST_DOCUMENT';

export const INITIAL_DATA = 'INITIAL_DATA';
export const SET_VALUE = 'SET_VALUE';