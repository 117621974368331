import * as configurationActions from '../actions/ConfigurationActions';

const initialState = {
    page: 0,
    cleanForm: null,
    busqueda: null,
    orden: null,

    showSuccessMsg: false,
    showWarningMsg: false,
    showErrorMsg: false,
    showInfoMsg: false,
    typeMessage: null,
    textMessage: null,
    textTitle: null,
}
const configurationReducerFuntion = (state = initialState, action) => {
    switch (action.type) {
        case configurationActions.SHOW_SUCCESS_MSG:
            return {
                ...state,
                showSuccessMsg: action.showSuccessMsg,
                typeMessage: 'success',
                textMessage: action.textMessage,
                textTitle: action.textTitle,
            };
        case configurationActions.SHOW_WARNING_MSG:
            return {
                ...state,
                showWarningMsg: action.showWarningMsg,
                typeMessage: 'warning',
                textMessage: action.textMessage,
                textTitle: action.textTitle,
            };
        case configurationActions.SHOW_ERROR_MSG:
            return {
                ...state,
                showErrorMsg: action.showErrorMsg,
                typeMessage: 'error',
                textMessage: action.textMessage,
                textTitle: action.textTitle,
            };

        case configurationActions.SHOW_INFO_MSG:
            return {
                ...state,
                showInfoMsg: action.showInfoMsg,
                typeMessage: 'info',
                textMessage: action.textMessage,
                textTitle: action.textTitle,
            };

        case configurationActions.CLEAN_FORM:
            return { ...state, cleanForm: action.cleanForm };

        default:
            return state;
    }
};

export default configurationReducerFuntion;