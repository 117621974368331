// MessagesContext.js
import React, { createContext, useContext, useEffect, useState } from 'react';

const MessagesContext = createContext();

export const MessagesProvider = ({ isDesktop, children }) => {
  const [messages, setMessages] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchMessages = async () => {
      try {
        let loadedMessages;
        if (isDesktop) {
          loadedMessages = await import('../assets/translate/messages.desktop.js');
        } else {
          loadedMessages = await import('../assets/translate/messages.nube.js');
        }
        setMessages(loadedMessages.MESSAGES);
        setLoading(false);
      } catch (err) {
        setError(err);
      }
    };

    fetchMessages();
  }, [isDesktop]);

  return (
    <MessagesContext.Provider value={{ messages, loading }}>
      {children}
    </MessagesContext.Provider>
  );
};

export const useMessages = () => useContext(MessagesContext);
