import * as LegalDocumentasActions from '../actions/LegalDocumentsActions';

const initialState = {
    documentSelected: undefined,
    booleanButton: true,

    listLegalDocuments: [],
    fetchingListLegalDocuments: false,

    fetchingSignDocument: false,

    formInitialData: undefined,
    status: "1",
    fetchingDocumentSelected: false,
    showGeneralCloseSession: true,

    flow: undefined,
    identityId: undefined,
    accessToken: undefined,
    documentTypeOwner: '1',
    transportKey: undefined,
    serviceId: undefined,
    licenseId: undefined,
    rfc: undefined,
    role: undefined,
    instanceId: undefined,
    sourceTypeId: undefined
}

const reducerLegalDocumentsFuntion = (state = initialState, action) => {
    switch (action.type) {
        case LegalDocumentasActions.GET_LIST_DOCUMENTS_REQUEST:
            return { ...state, fetchingListLegalDocuments: true }

        case LegalDocumentasActions.GET_LIST_DOCUMENTS_SUCCESS:
            return { ...state, fetchingListLegalDocuments: false, listLegalDocuments: action.listLegalDocuments }

        case LegalDocumentasActions.GET_LIST_DOCUMENTS_FAILURE:
            return { ...state, fetchingListLegalDocuments: false, listLegalDocuments: action.listLegalDocuments }

        case LegalDocumentasActions.SIGN_DOCUMENT_REQUEST:
            return { ...state, fetchingSignDocument: true }

        case LegalDocumentasActions.SIGN_DOCUMENT_SUCCESS:
            return { ...state, fetchingSignDocument: false }

        case LegalDocumentasActions.SIGN_DOCUMENT_FAILURE:
            return { ...state, fetchingSignDocument: false }

        case LegalDocumentasActions.DOCUMENT_SELECTED:
            return { ...state, documentSelected: action.documentSelected, fetchingDocumentSelected: action.fetchingDocumentSelected,}

        case LegalDocumentasActions.UPDATE_LIST_DOCUMENT:
            return { ...state, listLegalDocuments: action.listLegalDocuments}

        case LegalDocumentasActions.INITIAL_DATA:
            return { ...state, formInitialData: action.formInitialData, status: action.status}
        
        case LegalDocumentasActions.SET_VALUE:
            return {
                ...state,
                [action.payload.field]: action.payload.value
            };
        default:
            return state
    }
}

export default reducerLegalDocumentsFuntion;