import { takeLatest, call, put, select  } from 'redux-saga/effects';
import * as LegalDocumentsActions from '../actions/LegalDocumentsActions';
import * as ConfigurationActions from '../actions/ConfigurationActions';
import { listLegalDocumentsCall, sendDocumentSignedCall } from '../../services/LegalDocumentsServices';
import { Typography, Modal } from 'antd';
import { getSigningKey } from '../../services/CryptoService'
import { encryptAES, generateRandomKey, rsaOAEP256Encrypt } from '../../utils/encrypt/encrypt'
import store from '../store'
import { AppMessage } from '../../AppMessages';

const KEYLENGTH = 16

const EVENT = {
    CLOSE_IFRAME: 'closeIframe',
    SHOW_PORTAL: 'showPortal',
    REDIRECT: 'redirect'
}

const Flow = {
    VALIDATE_DOCUMENTS_TO_SIGN: 'ValidateDocumentsToSign',
    LOGIN: 'Login',
    EXTERNAL_COMPANY: 'ExternalCompany',
    ACTIVATION: 'Activation'
}

async function encryptCertificate(certificate, privateKey, password){

    if (certificate !== null && privateKey !== null && password !== null){
        const authorization = await select(state => state.LegalDocumentsReducer.accessToken);
        const responseCrypto = await call(getSigningKey, authorization);
        if (responseCrypto.status === 200) {
            const signingKey = responseCrypto.data.base64Key;
            const randomKey = generateRandomKey(KEYLENGTH);
            let transportKey = await call(rsaOAEP256Encrypt, randomKey, signingKey);
            return {
                certificateEncrypt: await encryptAES(certificate, randomKey),
                privateKeyEncrypt: await encryptAES(privateKey, randomKey),
                passwordEncrypt: await encryptAES(password, randomKey),
                transportKey: transportKey
            }
        }
    }
}

function sendEventToParent(event){
    window.parent.postMessage(event, '*'); 
}

function getBase64(file, privateKey, certificate) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
        console.log('result',reader.result);
        if(file.type === 'fiel/certificado.key'){
            privateKey = reader.result;
        } else if(file.type === 'fiel/certificado.cer'){
            certificate = reader.result;
        }
    };
    reader.onerror = function (error) {
        console.log('Error: ', error);
    };
}

function* getListLegalDocuments(action) {
    try {
        // Headers: identityId, rfc, serviceId, Cache-Control
        // Params: documentTypeOwner*, limit*, skip, orderBy*nombre, sortDirection*ASC, status, version, roleId
        let status = action.status ? '&status=' + action.status : '&status=1';
        let orderBy = '&orderBy=Name';
        let sortDirection = '&sortDirection=ASC';

        const authorization = yield select(state => state.LegalDocumentsReducer.accessToken);
        if (authorization === undefined) return;
        
        const identityId = yield select(state => state.LegalDocumentsReducer.identityId);
        const documentTypeOwner = `documentTypeOwner=${yield select(state => state.LegalDocumentsReducer.documentTypeOwner)}`
        const role = yield select(state => state.LegalDocumentsReducer.role);
        const roleId = role ? `&roleId=${role}` : '';
        const rfc = yield select(state => state.LegalDocumentsReducer.rfc);
        const serviceId = yield select(state => state.LegalDocumentsReducer.serviceId);
        const transportKey = yield select(state => state.LegalDocumentsReducer.transportKey);
        const licenseId = yield select(state => state.LegalDocumentsReducer.licenseId);
        
        const response = yield call(
            listLegalDocumentsCall, 
            documentTypeOwner, 
            status, 
            identityId, 
            roleId, 
            orderBy, 
            sortDirection, 
            rfc, 
            serviceId, 
            authorization, 
            transportKey, 
            licenseId
        );
        if (response.status === 200) {
            // Success
            const listLegalDocuments = response.data;

            if(listLegalDocuments[0])
                listLegalDocuments.forEach(document => {
                    const documentName = document?.originalName || document?.name;
                    document.acceptance = undefined; // inicialización de la variable acceptance indefinida para primera validación dentro del componente 'Documents'
                    document.booleanSigned = false;
                    document.label = documentName + ' - ' + document?.version;
                });
            yield put({ type: LegalDocumentsActions.GET_LIST_DOCUMENTS_SUCCESS, listLegalDocuments });
        } else if(response.status === 204){
            // No Content
            let listLegalDocuments = [ {noData: true, label: AppMessage.EMPTY_DOCUMENTS} ];
            yield put({ type: LegalDocumentsActions.GET_LIST_DOCUMENTS_SUCCESS, listLegalDocuments });
        } else if(response.code === "ERR_NETWORK") {
            const showErrorMsg = true;
            const textTitle = AppMessage.AUTH_ERROR;
            const textMessage = AppMessage.INVALID_TOKEN;

            let listLegalDocuments = [ {noData: true, label: response?.title} ];

            yield put({ type: LegalDocumentsActions.GET_LIST_DOCUMENTS_FAILURE, listLegalDocuments });
            yield put({  type: ConfigurationActions.SHOW_ERROR_MSG, showErrorMsg, textMessage, textTitle });

        } else{
            // Bad Request - Server Error
            const showErrorMsg = true;
            const textTitle = response.response.data.title;
            const textMessage = response.response.data.detail;

            let listLegalDocuments = [ {noData: true, label: response?.title} ];

            yield put({ type: LegalDocumentsActions.GET_LIST_DOCUMENTS_FAILURE, listLegalDocuments });
            yield put({  type: ConfigurationActions.SHOW_ERROR_MSG, showErrorMsg, textMessage, textTitle });
        }
    } catch (error) {
        console.log(error);
        yield put({ type: LegalDocumentsActions.GET_LIST_DOCUMENTS_FAILURE });
    }
}
export function* getListLegalDocumentsSaga() {
    yield takeLatest(LegalDocumentsActions.GET_LIST_DOCUMENTS_REQUEST, getListLegalDocuments);
}

function* sendDocumentsSigned(action) {
    try {
        //Headers: authorization*, rfc, certificate, privateKey, password, trasportKey
        let data = [];
        let certificate = null;
        let privateKey = null;
        let password = action.form.contraseña ? action.form.contraseña : null;

        const identityId = yield select(state => state.LegalDocumentsReducer.identityId);
        const licenseId = yield select(state => state.LegalDocumentsReducer.licenseId);
        const documentTypeOwner = yield select(state => state.LegalDocumentsReducer.documentTypeOwner);
        const rfc = yield select(state => state.LegalDocumentsReducer.rfc);
        const secondaryTransportKey = yield select(state => state.LegalDocumentsReducer.transportKey);
        const authorization = yield select(state => state.LegalDocumentsReducer.accessToken);
        const application = yield select(state => state.LegalDocumentsReducer.serviceId);
        const instance = yield select(state => state.LegalDocumentsReducer.instanceId);
        const flow = yield select(state => state.LegalDocumentsReducer.flow);
        const role = yield select(state => state.LegalDocumentsReducer.role);
        const sourceTypeId = yield select(state => state.LegalDocumentsReducer.sourceTypeId);

        const rfcBase64 = window.btoa(unescape(encodeURIComponent(rfc)));

        let headers = { 'Content-Type': 'application/json', 'Authorization': authorization, 'transportKey': secondaryTransportKey};

        if (action.form.archivos)
            action.form.archivos.fileList.forEach(file => {
                getBase64(file.originFileObj, privateKey, certificate)
            });

        action.signedDocuments.forEach(document => {
            if (document.acceptance === undefined) return;
            let metadata = [
                {
                    "key": "serie",
                    "value": document.signatureKey === 1 ? identityId : licenseId // Cuenta CONTPAQi
                }
            ];

            if(action.formInitialData){
                if(application)
                {
                    metadata.push({
                        "key": "application",
                        "value": application.trim()
                    })
                }
                if(role){ 
                    metadata.push({
                        "key": "rol",
                        "value": role.trim()
                    }) 
                }
                if(rfc){ 
                    metadata.push({
                        "key": "rfc",
                        "value": rfcBase64
                    }) 
                }
            }

            let signSourceTypeId = undefined;

            if (flow === Flow.LOGIN) signSourceTypeId = 2;
            else if (flow === Flow.ACTIVATION) signSourceTypeId = 1;
            else signSourceTypeId = 3

            let singleDocument = {
                'documentId': document.internalDocumentId,
                'sourceTypeId': sourceTypeId,
                'acceptance': document.acceptance === 'accept',
                'signSourceTypeId': signSourceTypeId,
                'metadata': metadata
            };
            data.push(singleDocument);
        });

        if(certificate){ headers = {  ...headers,  'certificate': certificate,  } }
        if(privateKey){ headers = {  ...headers,  'privateKey': privateKey,  } }
        if(password){ headers = {  ...headers,  'password': password,  } }
        if(secondaryTransportKey){ headers = {  ...headers,  'secondaryTransportKey': secondaryTransportKey,  } }

        const objMessageResponse = {title: AppMessage.DOCUMENT_SIGN, textMessage: []}
        let banderaError = false;
        let responseDetail = ''
        let signedOk = []
        let response = null;

        for (let index = 0; index < data.length; index++) {
            response = yield call(sendDocumentSignedCall, data[index], headers);
            if (response.status === 201) {
                objMessageResponse.textMessage.push(action.signedDocuments[index].label); 
                signedOk.push(data[index]);
                
                yield put({ type: ConfigurationActions.CLEAN_FORM, cleanForm: 'signatureComponent' });
                yield put({ type: LegalDocumentsActions.SIGN_DOCUMENT_SUCCESS });
                // yield put({ type: ConfigurationActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage, textTitle });
                yield put({ type: LegalDocumentsActions.GET_LIST_DOCUMENTS_REQUEST, status: 1, formInitialData: action.formInitialData });
                yield put({ type: LegalDocumentsActions.DOCUMENT_SELECTED, documentSelected: undefined });
            } else{
                banderaError = true;
                objMessageResponse.textMessage.push(action.signedDocuments[index].label); 
                responseDetail = response.detail;

                yield put({ type: ConfigurationActions.CLEAN_FORM, cleanForm: 'signatureComponent' });
                yield put({ type: LegalDocumentsActions.SIGN_DOCUMENT_FAILURE });
                // yield put({ type: ConfigurationActions.SHOW_ERROR_MSG, showErrorMsg, textMessage, textTitle });
            } 
        }

        if(objMessageResponse.textMessage.length > 0){
            if(signedOk.length === data.length) {
                if (flow === Flow.VALIDATE_DOCUMENTS_TO_SIGN){
                    sendEventToParent(EVENT.SHOW_PORTAL);
                }
                else if (flow === Flow.LOGIN){
                    sendEventToParent(EVENT.CLOSE_IFRAME);
                }
                else if (flow === Flow.EXTERNAL_COMPANY){
                    sendEventToParent(EVENT.REDIRECT);
                }
                else if (flow === Flow.ACTIVATION){
                    sendEventToParent(EVENT.CLOSE_IFRAME);
                }
            }
            else
            {
                const { info } = Modal;
                info({
                    title: AppMessage.LEGAL_DOCUMENTS_TITLE_ERROR,
                    centered: true,
                    content: <Typography.Text>
                        {banderaError ?  responseDetail : AppMessage.SIGNATURE_ERROR}
                    </Typography.Text>,
                    okText: 'Ok',
                    onOk() {
                        if (response === null || response.status === 409 || response.status >= 500)
                        {
                            if (flow === Flow.VALIDATE_DOCUMENTS_TO_SIGN){
                                sendEventToParent(EVENT.SHOW_PORTAL);
                            }
                            else if (flow === Flow.LOGIN){
                                sendEventToParent(EVENT.CLOSE_IFRAME);
                            }
                            else if (flow === Flow.EXTERNAL_COMPANY){
                                sendEventToParent(EVENT.REDIRECT);
                            }
                            else if (flow === Flow.ACTIVATION){
                                sendEventToParent(EVENT.CLOSE_IFRAME);
                            }
                        }
                    },
                });
            }
        }  

    } catch (error) {
        console.log(error);
        yield put({ type: LegalDocumentsActions.SIGN_DOCUMENT_FAILURE });
    }
}
export function* sendDocumentsSignedSaga() {
    yield takeLatest(LegalDocumentsActions.SIGN_DOCUMENT_REQUEST, sendDocumentsSigned);
}