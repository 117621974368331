import { useEffect, useRef, useState } from 'react';
import Documents from "../components/ComponentDocuments/Documents";
import Signature from '../components/ComponentSign/Signature';
import Alert from './Alert';
import { connect } from 'react-redux';
import { Typography } from "antd";
import "../styles/sass/global.sass";
import { ArrowRightOutlined, ArrowLeftOutlined, CheckOutlined } from '@ant-design/icons';

import terms from '../assets/svg/undraw_terms.svg';
import read from '../assets/svg/undraw_books.svg';
import confirm from '../assets/svg/undraw_confirmation.svg';

import {acceptedDomain} from '../endpoints.js';
import { MessagesProvider } from '../context/MessageContext';
import {IsJWT} from '../utils/jwt/jwt'

const LegalDocumentApp = ({
    setValue,
    documentSelected
}) => {
    const ref1 = useRef(null);
    const ref2 = useRef(null);
    const ref3 = useRef(null);
    const [ stateDocumentSelected, setStateDocumentSelected] = useState(documentSelected);
    const [isDesktop, setIsDesktop] = useState(false)

    const steps = [
        {
            title: 'SELECCIONA UN DOCUMENTO',
            description: 'Selecciona el documento que desees leer.',
            cover: (  <img alt="contrato" src={read} style={{ width: '40%' }} /> ),
            target: () => ref1.current,
            nextButtonProps : { children : (  <ArrowRightOutlined /> ),  className:'buttonTour' }
        },
        {
            title: 'SELECCIONA UNA OPCIÓN',
            description: 'Acepta o deniega el contenido del documento.',
            cover: ( <img  alt="contrato" src={confirm} style={{ width: '40%' }} /> ),
            target: () => ref2.current,
            nextButtonProps : { children : (  <ArrowRightOutlined /> ),  className:'buttonTour' },
            prevButtonProps : { children : ( <ArrowLeftOutlined /> ), className:'buttonTour' }
        },
        {
            title: 'FIRMA DE CONFORMIDAD', 
            description: (<>
                <Typography.Paragraph>
                    <Typography.Text> Clic en el botón para confimar. </Typography.Text>
                </Typography.Paragraph>
                <Typography.Paragraph>
                    <Typography.Text italic type="secondary">Nota: para poder firmar tendrás que haber aceptado o rechazado todos los documentos disponibles.</Typography.Text>
                </Typography.Paragraph>
            </>),
            cover: ( <img alt="contrato" src={terms} style={{ width: '40%' }} /> ),
            target: () => ref3.current,
            prevButtonProps : { children : ( <ArrowLeftOutlined /> ), className:'buttonTour' },
            nextButtonProps : { children : (  <CheckOutlined /> ), className:'buttonTour' },
        },
    ];

    useEffect(() => {        
        const handleMessage = (event) => {
            const formattedStringList = acceptedDomain.replace(/'/g, '"');
            const list = JSON.parse(formattedStringList);
            if (!list.includes(event.origin)) {
                return;
            }
            // Maneja los datos recibidos
            const data = event.data;
            if (data !== undefined && data.flow !== undefined)
            {
                Object.entries(data).forEach(([field, value]) => {
                    setValue(field, value)
                    if (field === 'accessToken'){
                        setIsDesktop(!IsJWT(value.replace('Bearer ', '')))
                    }
                });          
            }
        };

        window.addEventListener('message', handleMessage);

        return () => {
            window.removeEventListener('message', handleMessage);
        };
    });

    return (
        <MessagesProvider isDesktop={isDesktop}>
            <div className="legal-docs-app">
                <div className="legal-docs-wrapper">
                    <div className="legal-docs__content">
                        <Documents stepsData={steps} ref1={ref1} setStateDocumentSelected={setStateDocumentSelected}/>
                    </div>
                    <div className="legal-docs__sidebar">
                        <Signature ref2={ref2} ref3={ref3} stateDocumentSelected={stateDocumentSelected}/>
                    </div>
                </div>
            </div>
        </MessagesProvider>
    );
        
}

const mapStateToProps = (state) => {
    return {
        listLegalDocuments: state.LegalDocumentsReducer.listLegalDocuments,
        documentSelected: state.LegalDocumentsReducer.documentSelected,
        status: state.LegalDocumentsReducer.status
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setValue: (field, value) => {
            dispatch({type: 'SET_VALUE', payload: {field, value}});
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LegalDocumentApp);