import { Popconfirm } from "antd";

const ConfirmButton = ({
  title,
  okText,
  cancelText,
  placement = 'top',
  children,
  onConfirm = () => {},
  onCancel = () => {},
}) => {
  return (
    <Popconfirm
        icon={<></>}
        okButtonProps={{className: 'base-btn'}}
        cancelButtonProps={{className: 'base-btn base-btn--secondary'}}
        placement={placement}
        title={title}
        onConfirm={onConfirm}
        onCancel={onCancel}
        okText={okText}
        cancelText={cancelText}

    >
      {children}
    </Popconfirm>
  );
};

export default ConfirmButton;
