import { useState, useEffect } from 'react';
import { Form, Row, Col, Typography, Space, Radio, Upload, Button, Input, Tour, Alert, Spin, Image, Popover, Popconfirm } from 'antd';
import { InboxOutlined, LoadingOutlined, SafetyCertificateOutlined, KeyOutlined,InfoCircleOutlined  } from '@ant-design/icons';
import { connect, useSelector } from 'react-redux';
import imgCompleted from '../../assets/svg/undraw_completed.svg';
import imgDenied from '../../assets/svg/undraw_access_denied.svg';
import { useMessages } from '../../context/MessageContext';
import { replacePlaceholders } from '../../utils/string/replacePlaceholder';
import DocumentsHeader from '../molecules/documents-header';
import { FlowOptions, EventTypes } from '../../constants/config.contants';
import CloseIcon from '../atoms/close-icon';
import ConfirmButton from '../molecules/confirm-button';
// import FIEL from './FIEL.ts';

const { Text, Paragraph } = Typography;
const {Dragger} = Upload;

const Signature = ({
    ref2,
    ref3,
    booleanButton,
    listLegalDocuments,
    cleanForm,
    documentSelected,
    fetchingDocumentSelected,
    formInitialData,
    status,
    fetchingSignDocument,
    showGeneralCloseSession,
    stateDocumentSelected,
    onFetchingDocumentSelected,
    onCleanForm,
    onShowErrorMsg,
    onShowSuccessMsg,
    onSignedDocument,
    onUpdateAcceptanceByDocument
}) => {
    const [form] = Form.useForm();
    const [componentBooleanButton, setBooleanButton] = useState(booleanButton);
    const [open, setOpen] = useState(false);
    const [listLocal, setListLocal] = useState(listLegalDocuments);
    const [ fileListCer, setFileListCer] = useState([]);
    const [ fileListKey, setFileListKey] = useState([]);
    const [isBooleanSigned, setBooleanSigned] = useState(false)
    const { messages, loading } = useMessages();
    const [counterSignedDocuments, setCounterSignedDocuments] = useState(0);

    // const [data, setData] = React.useState(null);

    const identityId = useSelector((state) => state.LegalDocumentsReducer.identityId);
    const documentTypeOwner = useSelector((state) => state.LegalDocumentsReducer.documentTypeOwner);
    const flow = useSelector((state) => state.LegalDocumentsReducer.flow);

    const initialValues = {
        identityId: identityId,
        documentTypeOwner: documentTypeOwner
    }

    useEffect(() => { 
        if(fetchingDocumentSelected) {
            
            setTimeout(function() {    
                onFetchingDocumentSelected(documentSelected, false);
            }, 500);
        }

        if (cleanForm && (cleanForm === 'signatureComponent' || cleanForm === 'signatureForm')) {
            form.resetFields();
            setBooleanButton(true);
            setOpen(false);
            onCleanForm();
        }

        if (documentSelected)
        {
            form.resetFields();
            setBooleanSigned(documentSelected.booleanSigned);
            if (documentSelected.acceptance !== undefined){
                form.setFieldsValue({
                    radioRequired: documentSelected.acceptance,
                    radioNotRequiredADP: documentSelected.acceptance,
                    radioNotRequiredTYC: documentSelected.acceptance,
                    radioNotRequired: documentSelected.acceptance
                })
            }
        }
        
    }, [documentSelected, ref2, stateDocumentSelected]);

    const onFinish = (values) => {
        if(documentSelected.requiresCertificate && (documentSelected.documentTypeOwner === 2 ||  documentSelected.documentTypeOwner === 4)){
            if(values.key.fileList.length === 0 || values.cer.fileList.length === 0){
                onShowErrorMsg( `Es necesario cargar los archivos requeridos para continuar`, 'Error al firmar');
            } else {
                // fetch("/api")
                //     .then((res) => res.json())
                //     .then((data) => setData(data.message));

                // console.log('data API', data);

                setFileListCer([]);
                setFileListKey([]);

                // const certFile = fs.readFileSync(values.cer.file.originFileObj, 'binary');
                // const keyFile = fs.readFileSync(values.key.file.originFileObj, 'binary');
                // console.log('certFile', certFile);
                // console.log('keyFile', keyFile);

                // const fiel = Credential.create(certFile, keyFile, values.contraseña);
                // const certificado = fiel.certificate();
                // console.log(certificado.rfc()); // el RFC del certificado

                onSignedDocument(listLegalDocuments, values, formInitialData);
            }
        } else {
            onSignedDocument(listLegalDocuments, values, initialValues);
        }
        
    };

    const onChangeRadio = (value) => {
        let listLocalRadio = listLegalDocuments;
        let count = 0;
        listLocalRadio.forEach(doc => { if(doc.documentID === documentSelected.documentID){ doc.acceptance = value.target.value; doc.sign = true; } });
        onUpdateAcceptanceByDocument(listLocalRadio);
        setListLocal(listLocalRadio);
        listLocalRadio.forEach(docEdit => {  if(docEdit.acceptance)  count += 1; });
        if(count === listLocalRadio.length){ setBooleanButton(false); }
        setCounterSignedDocuments(count);
    };

    const handleLogoutClick = () => {
        window.parent.postMessage(EventTypes.CLOSE_SESSION, '*'); 
    }

    const handleCloseWindow = () => {
        window.parent.postMessage(EventTypes.CLOSE_IFRAME, '*'); 
    };

    const draggerPropsCER = {
        name: 'cer',
        multiple: false,
        beforeUpload: (file) => {
            console.log('file', file)
            const extension = file.name.split(".")[1];
            const isCERnKEY = extension === 'cer';
            if (!isCERnKEY) {
                // message.error();
                onShowErrorMsg( `El archivo: ${file.name} no tiene una extensión .cer`, 'Error al cargar');
            }
            return isCERnKEY || Upload.LIST_IGNORE;
        },
        onChange(info) {
            console.log('info',info);
            let fileList = [...info.fileList];

            if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
            } else {
                console.log('Entra a else');
                setFileListCer(fileList);
            }
            if (info.file.status === 'done') {
                // message.success();
                onShowSuccessMsg( `El archivo: ${info.file.name} se cargó correctamente.`, 'Archivo cargado');
                setFileListCer(fileList);
            } else if (info.file.status === 'error') {
                // message.error(`El archivo: ${info.file.name} falló en cargarse.`);
                onShowErrorMsg( `El archivo: ${info.file.name} falló en cargarse.`, 'Error al cargar');
                setFileListCer(fileList);
            }
        },
        progress: {
            strokeColor: {
                '0%': '#108ee9',
                '100%': '#87d068',
            },
            strokeWidth: 3,
            format: (percent) => percent && `${parseFloat(percent.toFixed(2))}%`,
        },
        onDrop(e) { console.log('Dropped files', e.dataTransfer.files); },
        action: '//jsonplaceholder.typicode.com/posts/',
        listType: 'picture',
        iconRender: () => < SafetyCertificateOutlined   />,
        className: "upload-list-inline",
    };

    const draggerPropsKEY = {
        name: 'key',
        className: "upload-list-inline",
        multiple: false,
        beforeUpload: (file) => {
            console.log('file', file)
            const extension = file.name.split(".")[1];
            const isCERnKEY = extension === 'key';
            if (!isCERnKEY) {
                // message.error(`El archivo: ${file.name} no tiene una extensión .key`);
                onShowErrorMsg( `El archivo: ${file.name} no tiene una extensión .key`, 'Error al cargar');
            }
            return isCERnKEY || Upload.LIST_IGNORE;
        },
        onChange(info) {
            console.log('info',info);
            let fileList = [...info.fileList];

            if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
            }else {
                setFileListKey(fileList);
            }
            if (info.file.status === 'done') {
                // message.success(`El archivo: ${info.file.name} se cargó correctamente.`);
                onShowSuccessMsg( `El archivo: ${info.file.name} se cargó correctamente.`, 'Archivo cargado');
                setFileListKey(fileList);
            } else if (info.file.status === 'error') {
                // message.error(`El archivo: ${info.file.name} falló en cargarse.`);
                onShowErrorMsg( `El archivo: ${info.file.name} falló en cargarse.`, 'Error al cargar');
                setFileListKey(fileList);
            }
        },
        progress: {
            strokeColor: {
                '0%': '#108ee9',
                '100%': '#87d068',
            },
            strokeWidth: 3,
            format: (percent) => percent && `${parseFloat(percent.toFixed(2))}%`,
        },
        onDrop(e) { console.log('Dropped files', e.dataTransfer.files); },
        action: '//jsonplaceholder.typicode.com/posts/',
        listType: 'picture',
        iconRender: () => < KeyOutlined  />
    };

    if (loading){
        return <></>
    }

    return (
        <div className="legal-docs-signature">
            <div className="legal-docs-signature__header">
                <DocumentsHeader title={messages.TITLE_SIGN_DOCUMENT} />
            </div>
            <div className="legal-docs-signature__body">
                <div className="legal-docs-signature__content">
                    { documentSelected && (<>
                        { (status === "1" || status === '0')  ? 
                        <Form form={form}  layout="vertical" requiredMark={false} onFinish={onFinish}>
                            { documentSelected.statusID === 2 &&  <Row justify={'center'}><Col span={24}><Alert message={messages.ACCEPTED_DOCUMENT} type="success" showIcon /></Col></Row> }
                            { documentSelected.statusID === 3 &&  <Row justify={'center'}><Col span={24}><Alert message={messages.REJECTED_DOCUMENT} type="warning" showIcon /></Col></Row> }
                            {/* {{#if requiresCertificate && (documentTypeOwner == 2 ||  documentTypeOwner == 4)}} */}
                            {documentSelected.requiresCertificate && (documentSelected.documentTypeOwner === 2 ||  documentSelected.documentTypeOwner === 4) && (
                                <Paragraph>
                                    <Text>{messages.FIEL_SIGNATURE_INFO}</Text>
                                </Paragraph>
                            )}
                            <div className="legal-docs-signature__radios">
                                <Popover placement="bottomRight" content={messages.DOCUMENT_SCROLL_ALERT}>
                                    <div className="legal-docs-signature__radios__info"><InfoCircleOutlined /></div>
                                </Popover>
                            {/* <!-- Id is required-->
                            {{#if Required}} */}
                            { documentSelected.required ? (<>
                                <Spin spinning={fetchingDocumentSelected} indicator={ <LoadingOutlined style={{  fontSize: 24, }} spin  /> }>
                                    <Form.Item name="radioRequired" rules={[{ required: true, message: messages.SELECT_OPTION_INFO }]} style={{padding:'10px', borderRadius: '1em', backgroundColor:'var(--theme-lightgray-bg)',}}>
                                        {/* <Radio.Group ref={ref2} onChange={onChangeRadio} listLegalDocuments={listLocal}> */}
                                        <Radio.Group onChange={onChangeRadio} listLegalDocuments={listLocal} >
                                            <Space direction="vertical">
                                                <Radio disabled={ !isBooleanSigned || (documentSelected.statusID === 2 || documentSelected.statusID === 3) } value={'accept'}>
                                                    {replacePlaceholders(messages.AGREED_SIGN_DOCUMENT, documentSelected.label)}
                                                </Radio>
                                            </Space>
                                        </Radio.Group>
                                    </Form.Item> 
                                </Spin>
                            </>): (<>
                                <Spin spinning={fetchingDocumentSelected} indicator={ <LoadingOutlined style={{  fontSize: 24, }} spin  /> }>
                                    { documentSelected.documentID === 2 ?
                                        <Form.Item  name="radioNotRequiredADP" rules={[{ required: true, message: messages.SELECT_OPTION_INFO }]}  style={{padding:'10px', borderRadius: '1em', backgroundColor:'var(--theme-lightgray-bg)',}}>
                                            {/* <Radio.Group ref={ref2} onChange={onChangeRadio}> */}
                                            <Radio.Group onChange={onChangeRadio} >
                                                <Space direction="vertical">
                                                    <Radio disabled={ !isBooleanSigned || (documentSelected.statusID === 2 || documentSelected.statusID === 3) } value={'accept'}>
                                                        {replacePlaceholders(messages.AGREED_SIGN_DOCUMENT, documentSelected.label)}
                                                    </Radio>
                                                    <Radio disabled={ !isBooleanSigned ? true : (documentSelected.statusID === 2 || documentSelected.statusID === 3) } value={'decline'}>
                                                        {replacePlaceholders(messages.REJECT_SIGN_DOCUMENT, documentSelected.label)}
                                                    </Radio>
                                                </Space>
                                            </Radio.Group>
                                        </Form.Item>
                                        : (documentSelected.documentID === 1 ?
                                            <Form.Item  name="radioNotRequiredTYC" rules={[{ required: true, message: messages.SELECT_OPTION_INFO }]} style={{padding:'10px', borderRadius: '1em', backgroundColor:'var(--theme-lightgray-bg)',}}>
                                                {/* <Radio.Group ref={ref2} onChange={onChangeRadio}> */}
                                                <Radio.Group onChange={onChangeRadio} >
                                                    <Space direction="vertical">
                                                        <Radio disabled={ !isBooleanSigned || (documentSelected.statusID === 2 || documentSelected.statusID === 3) } value={'accept'}>{replacePlaceholders(messages.AGREED_SIGN_DOCUMENT, documentSelected.label)}</Radio>
                                                        <Radio disabled={ !isBooleanSigned || (documentSelected.statusID === 2 || documentSelected.statusID === 3) } value={'decline'}>{replacePlaceholders(messages.REJECT_SIGN_DOCUMENT, documentSelected.label)}</Radio>
                                                    </Space>
                                                </Radio.Group>
                                            </Form.Item>
                                            :
                                            <Form.Item  name="radioNotRequired" rules={[{ required: true, message: messages.SELECT_OPTION_INFO }]} style={{padding:'10px', borderRadius: '1em', backgroundColor:'var(--theme-lightgray-bg)', zIndex: 8}}>
                                                {/* <Radio.Group ref={ref2} onChange={onChangeRadio}> */}
                                                <Radio.Group onChange={onChangeRadio} >
                                                    <Space direction="vertical">
                                                        <Radio disabled={ !isBooleanSigned || (documentSelected.statusID === 2 || documentSelected.statusID === 3) } value={'accept'}>{replacePlaceholders(messages.AGREED_SIGN_DOCUMENT, documentSelected.label)}</Radio>
                                                        <Radio disabled={ !isBooleanSigned || (documentSelected.statusID === 2 || documentSelected.statusID === 3) } value={'decline'}>{replacePlaceholders(messages.REJECT_SIGN_DOCUMENT, documentSelected.label)}</Radio>
                                                    </Space>
                                                </Radio.Group>
                                            </Form.Item>
                                        )
                                    }
                                </Spin>
                            </>) }
                            </div>
                            <Row justify='center' gutter={[8,8]}>
                                { documentSelected.requiresCertificate && (documentSelected.documentTypeOwner === 2 || documentSelected.documentTypeOwner === 4) ? (<>
                                
                                {/* { true ? (<> */}
                                    <Col span={24}>
                                        <Form.Item label={messages.RFC} name="rfc" rules={[{ required: false }]}>
                                            <Input disabled defaultValue={formInitialData.rfc}/>
                                        </Form.Item>
                                    </Col>

                                    <Col span={12}>
                                        <Form.Item label={messages.KEY_FILE_LOAD} name="key" rules={[{ required: true, message: messages.LOAD_FILE_INFO }]} required tooltip={messages.REQUIRED_CHOICE}>
                                            <Dragger disabled={componentBooleanButton} maxCount={1} {...draggerPropsKEY} style={{ borderRadius: '1em' }} onRemove={() => setFileListKey([])} fileList={fileListKey}>
                                                <Row justify={'space-around'}>
                                                    <Col span={24}>
                                                    <p className="ant-upload-drag-icon">
                                                        <InboxOutlined />
                                                    </p>
                                                    </Col>
                                                    <Text type="secondary" style={{fontSize:12}}>{messages.DRAG_KEY_FILE}</Text>
                                                </Row>
                                            </Dragger>
                                        </Form.Item>
                                    </Col>

                                    <Col span={12}>
                                        <Form.Item label={messages.CER_FILE_LOAD} name="cer" rules={[{ required: true, message: messages.LOAD_FILE_INFO }]} required tooltip={messages.REQUIRED_CHOICE}>
                                            <Dragger disabled={componentBooleanButton} maxCount={1} {...draggerPropsCER} style={{ borderRadius: '1em' }} onRemove={() => setFileListCer([])} fileList={fileListCer}>
                                                <Row justify={'space-around'}>
                                                    <Col span={24}>
                                                    <p className="ant-upload-drag-icon">
                                                        <InboxOutlined />
                                                    </p>
                                                    </Col>
                                                    <Text type="secondary" style={{fontSize:12}}>{messages.DRAG_CER_FILE}</Text>
                                                </Row>
                                            </Dragger>
                                        </Form.Item>
                                    </Col>

                                    <Col span={24} style={{paddingBottom:'2em'}}>
                                        <Form.Item label={messages.PASSWORD_FILE_LOAD} name="contraseña" rules={[{ required: true, message: messages.INPUT_PASSWORD_INFO }]} required tooltip={messages.REQUIRED_CHOICE}>
                                            <Input.Password disabled={componentBooleanButton} placeholder={messages.PASSWORD_FILE_LOAD} />
                                        </Form.Item>
                                    </Col>
                                </>):false}
                                
                                
                            </Row>
                            <div className="legal-docs-signature__cta">
                                <div className="legal-docs-signature__cta__btn">
                                    {(flow === FlowOptions.EXTERNAL_COMPANY || flow === FlowOptions.VALIDATE_DOCUMENTS_TO_SIGN) && (
                                        <>
                                            {counterSignedDocuments > 0 ? (
                                                <ConfirmButton
                                                    title={messages.CONFIRM_CLOSE_IFRAME}
                                                    onConfirm={handleCloseWindow}
                                                    okText={messages.CONFIRM_CLOSE_IFRAME_OK}
                                                    cancelText={messages.CONFIRM_CLOSE_IFRAME_CANCEL}
                                                >
                                                    <Button className="base-btn base-btn--secondary legal-docs-signature__cta__close">{messages.CLOSE_WINDOW}</Button>
                                                </ConfirmButton>
                                            ) : (
                                                <Button className="base-btn base-btn--secondary legal-docs-signature__cta__close" onClick={handleCloseWindow}>{messages.CLOSE_WINDOW}</Button>
                                            )}
                       
                                        </>
                                    )}
                                    <Button ref={ref3} className="base-btn" disabled={componentBooleanButton} type="primary" htmlType="submit" loading={fetchingSignDocument}>
                                        {messages.AGREED_SIGNATURE}
                                        {componentBooleanButton && (
                                            <Popover placement="topRight" content={messages.DOCUMENT_CONTINUE_ALERT}>
                                                <div className="legal-docs-signature__cta__info"><InfoCircleOutlined /></div>
                                            </Popover>
                                        )}
                                    </Button>
                                </div>
                                {(flow !== FlowOptions.EXTERNAL_COMPANY && flow !== FlowOptions.VALIDATE_DOCUMENTS_TO_SIGN) && (
                                    <div className="legal-docs-signature__cta__btn">
                                        <a className="base-link" onClick={handleLogoutClick}>{messages.CLOSE_SESSION}</a>
                                    </div>
                                )}
                            </div>
                        </Form> :  <>
                        { documentSelected.statusID === 2 &&  <Row justify={'center'}><Col span={24}><Alert message={messages.ACCEPTED_DOCUMENT} type="success" showIcon /></Col></Row> }
                        { documentSelected.statusID === 3 &&  <Row justify={'center'}><Col span={24}><Alert message={messages.REJECTED_DOCUMENT} type="warning" showIcon /></Col></Row> }
                        <Row justify='center' align='middle'>
                            {/* <Skeleton active /> */}
                            { documentSelected.statusID === 2 && <Col span={12} style={{ paddingTop: '40px' }} gutter={[8,8]}> <Image src={imgCompleted} preview={false}/> </Col>}
                            { documentSelected.statusID === 3 && <Col span={14} style={{ paddingTop: '40px' }} gutter={[8,8]}> <Image src={imgDenied} preview={false}/> </Col>}
                            
                        </Row>
                        </>
                        }
                    </>)}
                    <Tour open={open} onClose={() => setOpen(false)} nextButtonProps={'Siguiente'}/>
                </div>  
            </div>
        </div>
	);
};

const mapStateToProps = (state) => {
    return {
        documentSelected: state.LegalDocumentsReducer.documentSelected,
        booleanButton: state.LegalDocumentsReducer.booleanButton,
        listLegalDocuments: state.LegalDocumentsReducer.listLegalDocuments,
        fetchingSignDocument: state.LegalDocumentsReducer.fetchingSignDocument,
        formInitialData: state.LegalDocumentsReducer.formInitialData,
        status: state.LegalDocumentsReducer.status,
        fetchingDocumentSelected: state.LegalDocumentsReducer.fetchingDocumentSelected,
        cleanForm: state.ConfigurationReducer.cleanForm,
        showGeneralCloseSession: state.LegalDocumentsReducer.showGeneralCloseSession
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        onUpdateAcceptanceByDocument: (listLegalDocuments) => {
            dispatch({type: 'UPDATE_LIST_DOCUMENT', listLegalDocuments: [...listLegalDocuments]});
        },
        onSignedDocument: (signedDocuments, form, formInitialData) => {
            dispatch({type: 'SIGN_DOCUMENT_REQUEST', signedDocuments, form, formInitialData});
        },
        onFetchingDocumentSelected: (documentSelected, fetchingDocumentSelected) => {
            dispatch({ type: 'DOCUMENT_SELECTED', documentSelected, fetchingDocumentSelected });
        },
        onCleanForm: () => {
            dispatch({type: 'CLEAN_FORM', cleanForm: null});
        },
        onShowErrorMsg: (textMessage, textTitle) => {
            dispatch({ type: 'SHOW_ERROR_MSG', showErrorMsg: true, textMessage, textTitle });
        },
        onShowSuccessMsg: (textMessage, textTitle) => {
            dispatch({ type: 'SHOW_SUCCESS_MSG', showSuccessMsg: true, textMessage, textTitle  });
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Signature);