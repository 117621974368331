import React from 'react';
import './index.css';
import App from "./containers/App";
import { Provider } from "react-redux";
import { ConfigProvider } from "antd";
import esES from "antd/es/locale/es_ES";
import store from "./store/store";
import reportWebVitals from './reportWebVitals';
import { createRoot } from 'react-dom/client';
const container = document.getElementById('root');
const root = createRoot(container);
root.render(<React.StrictMode>
    <Provider store={store}>
      <ConfigProvider locale={esES}>
        <App />
      </ConfigProvider>
    </Provider>
</React.StrictMode>);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
