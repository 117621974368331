import PropTypes from 'prop-types';
import { Image } from 'antd';

const DocumentsHeader = ({logoAppSrc, title}) => {
    return (
        <div className="legal-docs-header">
            {logoAppSrc && (
                <div className="legal-docs-header__logo">
                    <Image width={120} src={logoAppSrc} preview={false}/>
                    <div className="legal-docs-header__reg">&reg;</div>
                </div>
            )}
            {title && (
                <div className={`legal-docs-header__content ${!logoAppSrc ? 'legal-docs-header__content--full':''}`}>
                    <div className="base-title">{title}</div>
                </div>
            )}
        </div>
    );
};

DocumentsHeader.propTypes = {
    logoAppSrc: PropTypes.string,
    title: PropTypes.string,
};

DocumentsHeader.defultProps = {
    logoAppSrc: '',
    title: '',
};

export default DocumentsHeader;
