export const AppMessage = {
    LEGAL_DOCUMENTS_TITLE_OK: 'EXITO',
    LEGAL_DOCUMENTS_TITLE_ERROR: 'ERROR AL GUARDAR EL(LOS) DOCUMENTO(S)',
    AUTH_ERROR: 'Error de autorización',
    INVALID_TOKEN: 'El token de autorización ha expirado o es inválido.',
    DOCUMENT_SIGN: 'Firmado de Documentos',
    SIGNATURE_OK: 'El proceso se realizó correctamente',
    SIGNATURE_ERROR: 'No se pudo guardar correctamente la aceptación de los documentos, inténtalo más tarde',
    EMPTY_DOCUMENTS: 'Sin documentos'
}
