import { useEffect, useState } from 'react';
import { Collapse, Modal} from 'antd';
import {FileSearchOutlined, FileDoneOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { SignatureOutlined, EyeOutlined  } from '@ant-design/icons';
import PDFView from './PDF';
import Loader from '../atoms/loader';
import DocumentsHeader from '../molecules/documents-header';
import { useMessages } from '../../context/MessageContext';

const Documents = ({
    setStateDocumentSelected
}) => {
    const dispatch = useDispatch();
    const validateRfc = require('validate-rfc');
    const [status, setStatus] = useState("1");
    const [initialLoaded, setInitialLoaded] = useState(false);
    const { messages, loading } = useMessages();

    const listLegalDocuments = useSelector((state) => state.LegalDocumentsReducer.listLegalDocuments);
    const fetchingListLegalDocuments = useSelector((state) => state.LegalDocumentsReducer.fetchingListLegalDocuments);

    const identityId = useSelector((state) => state.LegalDocumentsReducer.identityId);
    const accessToken = useSelector((state) => state.LegalDocumentsReducer.accessToken);
    const documentTypeOwner = useSelector((state) => state.LegalDocumentsReducer.documentTypeOwner);

    const initialValues = {
        identityId: identityId,
        accessToken: accessToken,
        documentTypeOwner: documentTypeOwner
    }

    const [modal, contextHolder] = Modal.useModal();

    useEffect(() => {
        if (listLegalDocuments && listLegalDocuments.length > 0 && !initialLoaded) {
            onCollapse(['0']);
            setInitialLoaded(true);
        }
    }, [listLegalDocuments]);

    useEffect(() => {
        if(!listLegalDocuments !== undefined && identityId !== undefined && accessToken !== undefined){
            dispatch({type: 'GET_LIST_DOCUMENTS_REQUEST', status: status, formInitialData: initialValues});
        }
    }, [identityId]);

    const scrollHandler = (event, elementSelected) => 
    {
        let targetElement = event.target;
        if (elementSelected.statusID === 0 || elementSelected.statusID === 1) {
            if (!elementSelected.booleanSigned && targetElement.scrollTop >0) {
                if (targetElement.offsetHeight + targetElement.scrollTop >= targetElement.scrollHeight -1) {
                    elementSelected.booleanSigned = true;
                    dispatch({type: 'SET_VALUE', payload: {field: 'documentSelected', value: elementSelected}});
                    setStateDocumentSelected(true);
                }
            }
        }
    }

    const onCollapse = (e) => {

        let elementSelected = {};
        if(e.length){
            elementSelected = listLegalDocuments[parseInt(e[0])];

            const wrappedEventHandler = (event) => scrollHandler(event, elementSelected);
            let allElements = document.getElementsByClassName("legal-documents-pdf");
            for (let element of allElements) {
                element.removeEventListener('scroll', wrappedEventHandler);
            }

            dispatch({type: 'SET_VALUE', payload: {field: 'showGeneralCloseSession', value: false}});
            dispatch({type: 'DOCUMENT_SELECTED', documentSelected: elementSelected, fetchingDocumentSelected: false });
            setStateDocumentSelected(false);
            
            let collapsableElement = document.getElementsByClassName("legal-documents-pdf")[parseInt(e[0])];
            collapsableElement.addEventListener('scroll', wrappedEventHandler);
        }
    };

    const handleChangeStatus = (value) => { 
        dispatch({type: 'GET_LIST_DOCUMENTS_REQUEST', status: value, formInitialData: initialValues});
        setStatus(value); 
        dispatch({ type: 'INITIAL_DATA', status: value, formInitialData: initialValues });
        dispatch({type: 'DOCUMENT_SELECTED', documentSelected: undefined, fetchingDocumentSelected: false });
        dispatch({type: 'CLEAN_FORM', cleanForm: 'signatureComponent'});
    };

    if (loading){
        return <></>
    }

    return (
        <div className="legal-docs-detail">
            <DocumentsHeader logoAppSrc={'/logo.svg'} title={messages.DOCUMENT_TITLE_UPDATE.toUpperCase()} />
            <div className="legal-docs-detail__body">
                {/* <Row justify='space-between'>
                    <Typography.Title level={5} style={{ color:'#5E5E5E'}}>{'Actualizamos nuestros documentos'.toUpperCase()} <Button type="link" icon={<QuestionCircleFilled/>} /></Typography.Title>
                    <Col style={{paddingTop: '1.8em'}}>
                        <Select
                            defaultValue={status}
                            variant="filled"
                            style={{  width: 120 }}
                            onChange={handleChangeStatus}
                            options={[ { value: '0', label: 'Todos', }, { value: '1', label: 'Pendiente', },  { value: '2', label: 'Aceptado',  },  { value: '3', label: 'Rechazado', }, ]}
                        />
                    </Col>
                </Row> */}
                { !fetchingListLegalDocuments ? (
                    <>
                        { listLegalDocuments && 
                            <Collapse defaultActiveKey={['0']}
                                className="legal-docs-detail__collapse"
                                onChange={(element) => onCollapse(element)}
                                bordered={false}
                                accordion
                                expandIcon={({ isActive }) => !isActive ? <SignatureOutlined className="base-icon--default" /> : <EyeOutlined className="base-icon--active" />}
                            >
                                { listLegalDocuments.map((document, index) => (
                                    <Collapse.Panel 
                                        key={index} forceRender 
                                        extra={
                                            document.sign ? (
                                                <FileDoneOutlined onClick={(event) => { event.stopPropagation() }} style={{ fontSize: '24px', color: 'var(--theme-default-color)' }} />
                                            ) : (
                                                <FileSearchOutlined style={{color: 'transparent'}} />
                                            )
                                        } 
                                        collapsible={listLegalDocuments[0]?.noData ? 'disabled' : 'header'} 
                                        header={
                                            <div className="legal-docs-detail__title">
                                                {document.label} 
                                            </div>}>
                                        <div className='legal-documents-pdf'>
                                            <PDFView/>
                                        </div>
                                    </Collapse.Panel>
                                ))}
                            </Collapse>
                        }
                    </>
                ) : (
                    <div className="legal-docs-detail__loader">
                        <Loader />
                    </div>
                )}
            </div>
        </div>
    );
}
export default Documents;